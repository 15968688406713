
#Menu {
  padding: 10px 0px;
  text-align: center;
  z-index: 50;
}

.Menu-element {
  width: 100%;
  padding: 10px 5px;
  font-size: 1.3rem;
  text-decoration: none;
  color: #efefef;
  text-transform: uppercase;
  display: inline-block;
}

.Menu-element-selected {
  background: linear-gradient(to right, rgba(255,0,0,0), rgba(255,255,255,.7));
}

.Menu-element:before {
  content: "[";
  margin-right: 10px;
  display: inline-block;
  opacity: 0;
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.Menu-element:after {
  content: "]";
  margin-left: 10px;
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.Menu-element:hover:before,
.Menu-element:hover:after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}
