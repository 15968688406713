
#PageContact a {
	color: unset;
}

#PageContact a:hover {
	color: #03e3e3;
	text-decoration: none;
}

#PageContact img {
	max-width: 100%;
}

#PageContact .PageContact-spaced {
	height: 100%;
	padding: 20px;
	vertical-align: middle;
	font-size: 12px;
}