
#PageNetwork {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

#PageNetwork .vis-network {
	position: absolute !important;
	width: 100% !important;
    height: 100% !important;
}

#PageNetwork canvas {
	width: 100%;
    height: 100%;
}

#PageNetwork .Menu-element {
	position: absolute;
	top: 10px;
	left: 10px;
}