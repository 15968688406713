
#InsideApp {
    width:  100%;
    height: 100%;
    overflow-x: hidden;
}

#InsideApp-wrapped {
    max-width: 1200px;
    margin: auto;
    padding: 40px 0px;
    height: 100%;
}

#InsideApp-content {
    padding: 20px 10px;
}

#InsideApp-ForkMe {
    position: absolute;
    right: 0;
    top: 0;
}

#InsideApp-links {
    text-align: center;
    padding: 50px 0px;
}

#InsideApp-links a {
    margin: 20px;
}