
body {
    margin: 0;
    padding: 0;
    font-family: "Tahoma", sans-serif;
    font-size: 18px;
    color: #ddd;
}

body {
    background-color: #193c6d;
    background-size: 100%;
    background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #003073), color-stop(100%, #029797));
    background-image: -webkit-linear-gradient(135deg, #003073, #029797);
    background-image: -moz-linear-gradient(45deg, #003073, #029797);
    background-image: -ms-linear-gradient(45deg, #003073 0, #029797 100%);
    background-image: -o-linear-gradient(45deg, #003073, #029797);
    background-image: linear-gradient(135deg, #003073, #029797);
    margin: 0px;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

#App {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

h1 {
	color: white;
    font-family: "Tahoma", sans-serif;
    font-weight: normal;
    padding: 10px 0px;
    margin: 10px 20px 40px 0px;
    border-bottom: solid 1px white;
}

h2 {
	color: white;
    font-family: "Tahoma", sans-serif;
    font-weight: normal;
    padding: 0px 10px 0px 10px;
}

h3 {
	color: white;
    font-family: "Tahoma", sans-serif;
    font-weight: normal;
    padding: 0px 10px 0px 10px;
}

a {
	color: #009fe3;
}

.row-spaced {
	padding-bottom: 40px ;
}

.full-page {
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.center {
	text-align: center;
}

/* BUTTON */

.bottom-right-buttons {
	position: absolute;
	bottom: 30px;
	right: 40px;
}

.bottom-right-buttons > button {
	margin-left: 5px;
}

.bottom-left-buttons {
	position: absolute;
	bottom: 30px;
	left: 40px;
}

.bottom-left-buttons > button {
	margin-left: 5px;
}

.top-right-buttons {
	position: absolute;
	top: 10px;
	right: 20px;
}

.top-right-buttons > button {
	margin-left: 5px;
}

.right-buttons {
	display: block;
	text-align: right;
}

.right-buttons > button {
	margin: 7px 2px;
}

button {
	display:inline-block;
	padding:0.3em 1.2em;
	margin:0 0.1em 0.1em 0;
	border:0.16em solid rgba(0,0,0,0);
	border-radius:2em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
	text-align:center;
	transition: all 0.2s;
	background-color: #009fe3;
	opacity: .5;
}

button.small-button {
	padding: 0.1em 0.4em !important;
	font-size: 12px;
}

button:hover {
	border-color: rgba(0,0,0,1);
	opacity: 1;
}

button:disabled {
	background-color: grey !important;
	cursor: default !important;
}

@media all and (max-width:30em){
	button {
		display:block;
		margin:0.2em auto;
	}
}

.blue-button {
	background-color: #009fe3;
}

.red-button {
	background-color: #e40613;
}

.link-button {
	background-color: transparent;
	color: #009fe3;
	text-shadow: none;
	transition: none;
}

.link-button:hover {
	border-color: transparent;
}

.link-button:disabled {
	background-color: transparent;
	cursor: default;
	color: lightgrey;
}

.selected-link-button {
	background-color: #009fe3 !important;
	color: white !important;
}

/* POPUP */

.Popup-full-size-content {
	height: 95%;
	width: calc(100% - 20px) !important;
	padding: 10px 20px;
	animation: fadeIn ease .5s;
	-webkit-animation: fadeIn ease .5s;
	-moz-animation: fadeIn ease .5s;
	-o-animation: fadeIn ease .5s;
	-ms-animation: fadeIn ease .5s;
}

.Popup-small-size-content {
	max-height: 90% !important;
	width: calc(100% - 20px) !important;
	padding: 10px 20px;
	animation: fadeIn ease .5s;
	-webkit-animation: fadeIn ease .5s;
	-moz-animation: fadeIn ease .5s;
	-o-animation: fadeIn ease .5s;
	-ms-animation: fadeIn ease .5s;
}

@media (min-width: 768px) {
    .Popup-full-size-content {
		width: 80% !important;
		max-width: 1200px !important;
	}

	.Popup-small-size-content {
		width: 50% !important;
		max-width: 900px !important;
	}
}


.popup-content {
	padding: 10px 20px 30px 20px !important;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #EEE !important;
	text-align: initial;
}

/* ANIMATION */

.fade-in {
	animation: fadeIn ease 2s;
	-webkit-animation: fadeIn ease 2s;
	-moz-animation: fadeIn ease 2s;
	-o-animation: fadeIn ease 2s;
	-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-moz-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-webkit-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-o-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
@-ms-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}

.resize-animation {
	-webkit-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	-moz-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	-o-transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
	transition:width 300ms ease-in-out, max-height 300ms ease-in-out;
}

/* NOTIFICATION */

.notification-info {
  background-color: #03e3e3 !important;
}

.notification-success {
  background-color: #51a351 !important;
}

.notification-warning {
  background-color: #f89406 !important;
}

.notification-error {
  background-color: #e40613 !important;
}

.notification-container {
	pointer-events: none;
}

/* BOX */

.box {
	text-align: center;
	padding: 10px 20px 20px 20px;
	margin: 20px 0px;
	background: linear-gradient(135deg, rgba(255,0,0,0), rgba(255,255,255,.25));
	height: calc(100% - 40px);
	display: flex;
  	justify-content: center;
  	align-items: center;
}

.box-logo {
	font-size: 60px;
}

.box-text {
	font-size: 25px;
	line-height: 1;
	margin-bottom: 10px;
}

.box-subtext {
	font-size: 13px;
}

/* LOADING BAR */

.LoadingBar {
	height: 10px !important;
	bottom: 0 !important;
	top: unset !important;
}

.LoadingBar > div {
	background: #03e3e3 !important;
}

.LoadingBar > div > div {
	display: none;
}